import React from 'react'
import { Link } from 'react-router-dom';
export default function Footer() {
  return (
    <footer>
        <ul>
            <h5>İletişim</h5>
            <li>Ardahan Ticaret ve Sanayi Odası Karagöl Mah. Kongre Cad. No:67
            Kat:4-5 Merkez/ARDAHAN</li>
            <li> Telefon: +90(478) 211 29 70</li>
            <li> Fax: +90(478) 211 30 36</li>
            <li> E-posta: ardahantso@tobb.org.tr</li>
        </ul>

        <ul>
          <h5>Bağlantılar</h5>
          <li>
            <Link to='/kullanimSartlari'>Kullanım Şartları</Link>
          </li>
          <li>
            <Link to='/gizlilikSozlesmesi'>Gizlilik Sözleşmesi</Link>
          </li>
          <li>
            <Link to='/iletisim'>İletişim Formu</Link>
          </li>
          <li>
            <Link to='/kurumsalLogo'>Kurumsal Logo</Link>
          </li>
          <li>
            <Link to='/organizasyonSemasi'>Kurum Organları</Link>
          </li>
        </ul>

        <ul>
          <h5>Hakkımızda</h5>
          <li>
            <Link to='/tarihcemiz'>Ardahan TSO Hakkında</Link>
          </li>
          <li>
            <Link to='/misyonVisyon'>Misyon ve Vizyon</Link>
          </li>
          <li>
            <Link to='/kalitePolitikasi'>Kalite Politikamız</Link>
          </li>
          <li>
            <Link to='/eskiBaşkanlarimiz'>Eski Başkanlarımız</Link>
          </li>
          <li>
            <Link to='/kurumsalLogo'>Kurumsal Logomuz</Link>
          </li>
        </ul>

        <ul>
          <h5>Odamız</h5>
          <li>
            <Link to='/yonetimKuruluUyeleri'>Yönetim Kurulu</Link>
          </li>
          <li>
            <Link to='/meclisUyeleri'>Meclis Üyeleri</Link>
          </li>
          <li>
            <Link to='/tarihce'>Ardahan Tarihçesi</Link>
          </li>
          <li>
            <Link to='/haberler'>Faaliyetler</Link>
          </li>
        </ul>
    </footer>
  )
}
