import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Navbar() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsAuthenticated(!!token); 
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/');
    };

  


    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">Anasayfa</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav" id='navBarNav'>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" id="hakkimizdaDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Hakkımızda
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="hakkimizdaDropdown">
                                <li><Link className="dropdown-item" to="/tarihcemiz">Tarihçemiz</Link></li>
                                <li><Link className="dropdown-item" to="/misyonVisyon">Misyon - Vizyon</Link></li>
                                <li><Link className="dropdown-item" to="/kalitePolitikasi">Kalite Politikamız</Link></li>
                                <li className="dropdown-submenu">
                                    <Link className="dropdown-item dropdown-toggle" to="#">Kurum Organları</Link>
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to="/organizasyonSemasi">Organizasyon Şeması</Link></li>
                                        <li><Link className="dropdown-item" to="/yonetimKuruluUyeleri">Yönetim Kurulu Üyeleri</Link></li>
                                        <li><Link className="dropdown-item" to="/meclisUyeleri">Meclis Üyeleri</Link></li>
                                        <li><Link className="dropdown-item" to="/genelSekreterlik">Genel Sekreterlik</Link></li>
                                        <li><Link className="dropdown-item" to="/meclisKomitesiUyeleri">Meclis Komitesi Üyeleri</Link></li>
                                    </ul>
                                </li>
                                <li><Link className="dropdown-item" to="/eskiBaşkanlarimiz">Eski Başkanlarımız</Link></li>
                                <li><Link className="dropdown-item" to="/kurumsalLogo">Kurumsal Logomuz</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" id="hizmetlerDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Hizmetler
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="hizmetlerDropdown">
                                <div className="dropdown-columns">
                                    <div className="dropdown-column">
                                        <li><Link className="dropdown-item" to="/muhasebeFinansBirimi">Muhasebe ve Finans Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/sigortaKayıtBirimi">Sigortacılık Levha Kayıt Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/basinYayinDepartmani">Basın Yayın Departmanı</Link></li>
                                        <li><Link className="dropdown-item" to="/tpadkBirimi">TPADK (Alkol ve Tütün Ruhsatı) Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/egitimOrganizasyonBirimi">Eğitim ve Organizasyon Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/halklaIliskilerBirimi">Halkla İlişkiler Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/argeBirimi">AR-GE Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/bilgiIslemHizmetleri">Bilgi İşlem Hizmetleri</Link></li>
                                        <li><Link className="dropdown-item" to="/ticariVizeHizmetleri">Ticari Vize Hizmetleri</Link></li>
                                        <li><Link className="dropdown-item" to="/ticaretSicilMudurlugu">Ticaret Sicil Müdürlüğü</Link></li>
                                        <li><Link className="dropdown-item" to="/mevzuatKanunlar">Mevzuat ve Kanunlar</Link></li>
                                        <li><Link className="dropdown-item" to="/kBelgeYetkisi">"K" Türü Yetki Belgesi Bürosu</Link></li>
                                        <li><Link className="dropdown-item" to="/tseTemsilcilik">T.S.E Mali Temsilciliği</Link></li>
                                    </div>
                                    <div className="dropdown-column">
                                        <li><Link className="dropdown-item" to="/kapasiteRaporu">Kapasite Raporu İşlemleri</Link></li>
                                        <li><Link className="dropdown-item" to="/yaziIsleri">Yazı İşleri Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/deikBirimi">DEİK Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/igemeBirimi">İGEME Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/abKaliteAkreditasyon">AB - Kalite Ve Akreditasyon Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/yatirimDanısmanlıgı">Yatırım Danışmanlığı Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/insanKaynaklari">İnsan Kaynakları Birimi</Link></li>
                                        <li><Link className="dropdown-item" to="/odaSicilMemurlugu">Oda Sicil Memurluğu</Link></li>
                                        <li><Link className="dropdown-item" to="/isMakinesiIslemleri">İş Makinesi Tescil İşlemleri</Link></li>
                                        <li><Link className="dropdown-item" to="/ekspertizRaporu">Ekspertiz Raporu İşlemleri</Link></li>
                                    </div>
                                </div>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" id="uyelerimizDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Üyelerimiz
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="uyelerimizDropdown">
                                <li><Link className="dropdown-item" to="https://uye.tobb.org.tr/organizasyon/firma-index.jsp">Online Belge</Link></li>
                                <li><Link className="dropdown-item" to="https://uye.tobb.org.tr/hizliaidatodeme.jsp">Online Hızlı Fatura Ödeme</Link></li>
                                <li><Link className="dropdown-item" to="http://uygulama.tobb.net/UyeBilgiSorgulama/welcome.do?chc=MTIwNQ==QVJEQUhBTiBU3UNBUkVUIFZFIFNBTkFZ3SBPREFTSQ==">Üye Liste Sorgulama</Link></li>
                                <li><Link className="dropdown-item" to="http://uygulama.tobb.net/UyeBilgiSorgulama/welcome.do?chc=MTIwNQ==QVJEQUhBTiBU3UNBUkVUIFZFIFNBTkFZ3SBPREFTSQ==%20&post=uyeBilgiServisi">Üye Bilgi Servisi</Link></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" id="bilgiBankasiDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Bilgi Bankası
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="bilgiBankasiDropdown">
                                <li><Link className="dropdown-item" to="/haberler">Haberler</Link></li>
                                <li><Link className="dropdown-item" to="/basin">Basında Ardahan TSO</Link></li>
                                <li><Link className="dropdown-item" to="/faaliyetRaporu">Faaliyet Raporları</Link></li>
                                <li><Link className="dropdown-item" to="/raporYayin">Raporlar ve Yayınlar</Link></li>
                                <li><Link className="dropdown-item" to="/videoGaleri">Video Galeri</Link></li>
                                <li><Link className="dropdown-item" to="/ithalatciFirmalar">İthalatçı Firmalar</Link></li>
                                <li><Link className="dropdown-item" to="/ulkeRaporlari">Ülke Raporları</Link></li>
                            </ul>
                        </li>


                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" id="hakkimizdaDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Ardahan
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="hakkimizdaDropdown">
                                <li><Link className="dropdown-item" to="/tarihce">Tarihçe</Link></li>
                                <li><Link className="dropdown-item" to="/genelBilgiler">Genel Bilgiler</Link></li>
                                <li><Link className="dropdown-item" to="/kulturVeTurizm">Kültür ve Turizm</Link></li>
                                <li><Link className="dropdown-item" to="/ekonomi">Ekonomi</Link></li>
                                <li className="dropdown-submenu">
                                    <Link className="dropdown-item dropdown-toggle" to="#">Ürünler</Link>
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to="/tarimsalUrunler">Tarımsal Ürünler</Link></li>
                                        <li><Link className="dropdown-item" to="/hayvansalUrunler">Hayvansal Ürünler</Link></li>
                                        <li><Link className="dropdown-item" to="/dogalUrunler">Doğal Ürünler</Link></li>
                                    </ul>
                                </li>
                                <li><Link className="dropdown-item" to="/resimGalerisi">Resim Galerisi</Link></li>
                               
                            </ul>
                        </li>

                        <li className="nav-item ">
                            <Link className="nav-link" to="https://kalite.ardahantso.org.tr/login/tabid/20927/Default.aspx?returnurl=%2f"  role="button"  aria-expanded="false">Kalite ve Akreditasyon</Link>
                        </li>

                        <li className="nav-item ">
                            <Link className="nav-link" to="/iletisim"  role="button"  aria-expanded="false">İletişim</Link>
                        </li>

                    </ul>
                    <ul className="navbar-nav ms-auto">
                        {isAuthenticated ? (

                            <div className="auth">
                                 <li className="nav-item">
                                <Link className="nav-link" to="/admin">Yönetici</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/" onClick={handleLogout}>Çıkış Yap</Link>
                            </li>
                            </div>
                           
                        ) : (
                            <li className="nav-item">
                                <Link className="nav-link" to="/login">Giriş Yap</Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}
